<template>
  <div class="jumbotron p-5 rounded-lg" style="margin-bottom: 2em">
    <h1 class="display-4">The true story behind RML.</h1>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-12 text-start">
        <br>
        <p>In 1895, Louis Hendricks, a teacher in Tilden School, requested the Richland Township Board establish a library. The library was set up in the home of Mr. and Mrs. Walter Pettit. Mrs. Lucy Carver, who shared their home, was in charge of the books. For several years, the book collection was moved among several other homes until the library disbanded and the books were given to the township's schools.</p>
        <br>
        <p>In 1942, Mary C. Rauchholz applied to the Richland Township Board for permission to re-establish the Richland Township Library. The Board gave approval and appointed a Library Board to oversee the library and hired Mary as librarian.</p>
        <br>
        <p>The library began in a small corner of Mary's Book Store which Mary ran at the time. Soon the library took up too much space in the shop and Mary purchased and renovated a small home for use as the library.</p>
        <br>
        <p>Mary remained as librarian until she passed away in 1967 at the age of 75 years. Her sister, Mrs. Edna McLeish, donated the library building to Richland Township to be used for library purposes. The name of the library was officially changed to Rauchholz Memorial Library in honor of Mary.</p>
        <br>
        <p>In 1976, the Library moved to its current location when Richland Township and the Library built a new building together. The residents of Richland Township approved a 3/4 millage in 1988 for the operating of the township's library. In 2004, the Township built a new municipal building and the library renovated and expanded into it's current location.</p>
        <br>
        <p>In 2015, the library received $919,214.10 from the James and Mary Jean Foye estate. Plans are to build a new library behind our current location to have a facility designed for today's technology needs and library expectations.</p>
        <br>
      </div>

      <div class="col-md-4 col-sm-12 text-center">
        <div>
          <img src="../history/images/original_library.jpg" class="img-rounded" alt="Original Library 1942" width="340" height="240">
          <p><small>Original Library 1942-1976
            <br>Located on Gratiot in Hemlock</small></p>
        </div>
        <div>
          <img src="../history/images/original_librarian.jpg" class="img-rounded" alt="First Librarian" width="260" height="300">
          <p><small>First Librarian: Mary C. Rauchholz 1942-1966<br>Students: Debbie Carrol and Paul Wolfgram
            <br>Picture taken in 1957</small></p>
        </div>
        <div>
          <img src="../history/images/librarians.jpg" class="img-rounded" alt="Librarians 1975" width="340" height="240">
          <p><small>Librarians: Marilyn Clare and Connie Hutfilz, 1975</small></p>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'history'
}
</script>

<style scoped>
.jumbotron {
  /*background-color: #4e6738;*/
  background-color: #7a9c4e;
  color: #FFFFFF;
  text-align: center;
}

button{
  outline-color: #FFFFFF;
}

a {
  color: #FFFFFF;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
</style>
